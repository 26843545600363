<template>
  <v-container style="height: 100vh">
    <v-layout align-center justify-center column fill-height>
      <v-flex row align-center>
        <v-progress-circular
          indeterminate
          :size="50"
          color="primary"
          class=""
        ></v-progress-circular>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Loading",
};
</script>
